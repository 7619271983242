import React from 'react';

export function fixSpacedUnits(unit?: string | null, stringify = false): any {
  if (!unit || unit.indexOf(' ') < 0) return '';
  const fixedUnitsArray: (JSX.Element | string)[] = unit
    .split(' ')
    .map(unit => (unit ? fixUnit(unit, stringify) : '')); // eslint-disable-line
  return stringify
    ? fixedUnitsArray.join(' ')
    : fixedUnitsArray.map(el => <React.Fragment key={`fragment-${el}`}> {el}</React.Fragment>);
}

export function fixUnit(unit?: string | null, stringify = false) {
  if (!unit) return '';
  if (unit.includes(' ')) {
    return fixSpacedUnits(unit, stringify);
  }
  const fixedUnits: Record<string, string> = {
    unit: 'stuk',
    hour: 'uur',
    m2: 'm²',
    'm2K/W': 'm²K/W',
    m3: 'm³',
    'm3 / jaar': 'm³ / jaar',
    'm3/jaar': 'm³ / jaar',
    CO2: 'CO₂',
    'kg CO2': 'kg CO₂',
    'ton CO2': 'ton CO₂',
    'kg CO2 / jaar': 'kg CO₂ / jaar',
    'kg CO2/jaar': 'kg CO₂ / jaar',
    euro: '€',
    'euro / jaar': '€ / jaar',
    'euro/jaar': '€ / jaar',
    'kWh/m2': 'kWh / m²',
    'kWh / m2': 'kWh / m²',
    'kWh/m2/jaar': 'kWh / m² / jaar',
    'kWh / m2 / jaar': 'kWh / m² / jaar',
    '€ / m3': '€ / m³',
  };
  if (stringify && Object.keys(fixedUnits).includes(unit)) return fixedUnits[unit];
  switch (unit) {
    case 'm2':
      return (
        <span>
          m<sup>2</sup>
        </span>
      );
    case 'm2K/W':
      return (
        <span>
          m<sup>2</sup>K/W
        </span>
      );
    case 'm3':
      return (
        <span>
          m<sup>3</sup>
        </span>
      );
    case 'm3 / jaar':
    case 'm3/jaar':
      return (
        <span>
          m<sup>3</sup> / jaar
        </span>
      );

    case 'CO2':
      return (
        <span>
          CO<sub>2</sub>
        </span>
      );
    case 'kg CO2':
      return (
        <span>
          kg CO<sub>2</sub>
        </span>
      );

    case 'ton CO2':
      return (
        <span>
          kg CO<sub>2</sub>
        </span>
      );

    case 'kg CO2 / jaar':
    case 'kg CO2/jaar':
      return (
        <span>
          kg CO<sub>2</sub> / jaar
        </span>
      );
    case 'kWh / m2':
    case 'kWh/m2':
      return (
        <span>
          kWh / m<sup>2</sup>
        </span>
      );
    case 'kWh / m2 / jaar':
    case 'kWh/m2/jaar':
      return (
        <span>
          kWh / m<sup>2</sup> / jaar
        </span>
      );
    default:
      return unit;
  }
}

export default fixUnit;
