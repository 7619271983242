import { gql } from '@apollo/client';
import { UPDATE_USERLESS_ENERGY_PRICES as UPDATE_ENERGY_PRICES } from '~/hooks/usePersonalEnergyPrices';

// MUTATIONS

export const UPDATE_CURRENT_OCCUPANT = gql`
  mutation updateCurrentOccupant_Userless($houseId: ID!, $isCurrentOccupant: Boolean!) {
    updateCurrentOccupant_Userless(houseId: $houseId, isCurrentOccupant: $isCurrentOccupant) {
      currentOccupant
    }
  }
`;

export const UPDATE_APARTMENT_TYPE = gql`
  mutation updateApartmentType_Userless($houseId: ID!, $apartmentProperties: [String]) {
    updateApartmentType_Userless(houseId: $houseId, apartmentProperties: $apartmentProperties) {
      id
      type
    }
  }
`;

export const UPDATE_HOUSE_WALL_CAVITY = gql`
  mutation houseHasWallCavity_Userless($houseId: ID!, $hasWallCavity: Boolean) {
    houseHasWallCavity_Userless(houseId: $houseId, hasWallCavity: $hasWallCavity) {
      id
      walls {
        cavity
      }
    }
  }
`;

export const UPDATE_HOUSE_WALL_INSULATION = gql`
  mutation houseHasWallInsulation_Userless($houseId: ID!, $hasWallInsulation: Boolean) {
    houseHasWallInsulation_Userless(houseId: $houseId, hasWallInsulation: $hasWallInsulation) {
      id
      walls {
        rc
      }
    }
  }
`;

export const UPDATE_HOUSE_WALL_INSULATION_EXTRA = gql`
  mutation houseHasExtraWallInsulation_Userless($houseId: ID!, $hasExtraWallInsulation: Boolean) {
    houseHasExtraWallInsulation_Userless(
      houseId: $houseId
      hasExtraWallInsulation: $hasExtraWallInsulation
    ) {
      id
      walls {
        rc
      }
    }
  }
`;

export const UPDATE_HOUSE_HAS_CRAWLSPACE = gql`
  mutation houseHasCrawlspace_Userless($houseId: ID!, $hasCrawlspace: Boolean) {
    houseHasCrawlspace_Userless(houseId: $houseId, hasCrawlspace: $hasCrawlspace) {
      id
      crawlspace {
        compartments
      }
    }
  }
`;

export const UPDATE_HOUSE_FLOOR_INSULATION = gql`
  mutation houseHasFloorInsulation_Userless($houseId: ID!, $hasFloorInsulation: Boolean) {
    houseHasFloorInsulation_Userless(houseId: $houseId, hasFloorInsulation: $hasFloorInsulation) {
      id
      floor {
        rc
      }
    }
  }
`;

export const UPDATE_HOUSE_FLOOR_INSULATION_EXTRA = gql`
  mutation houseHasExtraFloorInsulation_Userless($houseId: ID!, $hasExtraFloorInsulation: Boolean) {
    houseHasExtraFloorInsulation_Userless(
      houseId: $houseId
      hasExtraFloorInsulation: $hasExtraFloorInsulation
    ) {
      id
      floor {
        rc
      }
    }
  }
`;

export const UPDATE_HOUSE_ROOF_INSULATION = gql`
  mutation houseHasRoofInsulation_Userless($houseId: ID!, $hasRoofInsulation: Boolean) {
    houseHasRoofInsulation_Userless(houseId: $houseId, hasRoofInsulation: $hasRoofInsulation) {
      id
      roof {
        rc
      }
    }
  }
`;

export const UPDATE_HOUSE_ROOF_INSULATION_EXTRA = gql`
  mutation houseHasExtraRoofInsulation_Userless($houseId: ID!, $hasExtraRoofInsulation: Boolean) {
    houseHasExtraRoofInsulation_Userless(
      houseId: $houseId
      hasExtraRoofInsulation: $hasExtraRoofInsulation
    ) {
      id
      roof {
        rc
      }
    }
  }
`;

export const UPDATE_HOUSE_LIVING_GLAZING = gql`
  mutation houseHasLivingGlazing_Userless($houseId: ID!, $livingGlassType: QuestioningGlassType) {
    houseHasLivingGlazing_Userless(houseId: $houseId, glassType: $livingGlassType) {
      id
      windowsZTAU {
        livingU
      }
    }
  }
`;

export const UPDATE_HOUSE_SLEEPING_GLAZING = gql`
  mutation houseHasSleepingGlazing_Userless(
    $houseId: ID!
    $sleepingGlassType: QuestioningGlassType
  ) {
    houseHasSleepingGlazing_Userless(houseId: $houseId, glassType: $sleepingGlassType) {
      id
      windowsZTAU {
        sleepingU
      }
    }
  }
`;

export const UPDATE_HOUSE_PV_SYSTEM = gql`
  mutation houseHasPVSystem_Userless($houseId: ID!, $panelAmount: Int) {
    houseHasPVSystem_Userless(houseId: $houseId, panelAmount: $panelAmount) {
      id
      pvSystemPower
    }
    updatePvPanelAmount_Userless(houseId: $houseId, panelAmount: $panelAmount) {
      solar {
        currentSystem {
          panels
        }
      }
    }
  }
`;

export const UPDATE_HOUSE_BOILER_AGE = gql`
  mutation houseHasBoilerAge_Userless($houseId: ID!, $boilerAge: Int) {
    houseHasBoilerAge_Userless(houseId: $houseId, age: $boilerAge) {
      id
      heating {
        boilerAge
      }
    }
  }
`;

export const UPDATE_HOUSE_WEATHER_STRIPPING = gql`
  mutation houseHasWeatherStripping_Userless($houseId: ID!, $hasWeatherStripping: Boolean!) {
    houseHasWeatherStripping_Userless(
      houseId: $houseId
      hasWeatherStripping: $hasWeatherStripping
    ) {
      id
      heating {
        weatherStripping
      }
    }
  }
`;

export const UPDATE_HOUSE_SLURPERS = gql`
  mutation addCurrentSlurpers_Userless($houseId: ID!, $slurpers: [String]!) {
    addCurrentSlurpers_Userless(houseId: $houseId, slurpers: $slurpers) {
      currentSlurpers
      slurperConsumption
    }
  }
`;

export const UPDATE_CONSUMPTION = gql`
  mutation updateConsumption_Userless($houseId: ID!, $consumption: ConsumptionInput!) {
    updateConsumption_Userless(houseId: $houseId, consumption: $consumption) {
      consumption {
        gas
        electricity
      }
      slurperConsumption
    }
  }
`;

export const UPDATE_RESIDENT_AMOUNT = gql`
  mutation houseResidentAmount_Userless($houseId: ID!, $residentAmount: Int) {
    houseResidentAmount_Userless(houseId: $houseId, amount: $residentAmount) {
      id
      residents
      energy {
        gasConsumption
        electricityConsumption
        electricityProduction
      }
    }
  }
`;

export const UPDATE_HOUSE_HAS_BATH = gql`
  mutation houseHasBath_Userless($houseId: ID!, $hasBath: Boolean!) {
    houseHasBath_Userless(houseId: $houseId, hasBath: $hasBath) {
      id
      appliances {
        bathtub
      }
    }
  }
`;

export const UPDATE_HOUSE_HAS_DISHWASHER = gql`
  mutation houseHasDishWasher_Userless($houseId: ID!, $hasDishWasher: Boolean!) {
    houseHasDishWasher_Userless(houseId: $houseId, hasDishWasher: $hasDishWasher) {
      id
      appliances {
        dishwasher
      }
    }
  }
`;

export const UPDATE_HOUSE_IS_VVE = gql`
  mutation houseIsVVE_Userless($houseId: ID!, $isVve: Boolean) {
    houseIsVVE_Userless(houseId: $houseId, isVve: $isVve) {
      id
      vve
    }
  }
`;

export const FINISH_INITIAL_QUESTIONS = gql`
  mutation finishInitialQuestions_Userless($houseId: ID!, $email: String) {
    finishInitialQuestions_Userless(houseId: $houseId, email: $email) {
      id
      initialQuestionsAnswered
    }
  }
`;

const savingsCheckMutationsUserless = {
  UPDATE_CURRENT_OCCUPANT,
  UPDATE_APARTMENT_TYPE,
  UPDATE_HOUSE_WALL_CAVITY,
  UPDATE_HOUSE_WALL_INSULATION,
  UPDATE_HOUSE_WALL_INSULATION_EXTRA,
  UPDATE_HOUSE_HAS_CRAWLSPACE,
  UPDATE_HOUSE_FLOOR_INSULATION,
  UPDATE_HOUSE_FLOOR_INSULATION_EXTRA,
  UPDATE_HOUSE_ROOF_INSULATION,
  UPDATE_HOUSE_ROOF_INSULATION_EXTRA,
  UPDATE_HOUSE_LIVING_GLAZING,
  UPDATE_HOUSE_SLEEPING_GLAZING,
  UPDATE_HOUSE_PV_SYSTEM,
  UPDATE_HOUSE_BOILER_AGE,
  UPDATE_HOUSE_WEATHER_STRIPPING,
  UPDATE_HOUSE_SLURPERS,
  UPDATE_CONSUMPTION,
  UPDATE_ENERGY_PRICES,
  UPDATE_RESIDENT_AMOUNT,
  UPDATE_HOUSE_HAS_BATH,
  UPDATE_HOUSE_HAS_DISHWASHER,
  UPDATE_HOUSE_IS_VVE,
  FINISH_INITIAL_QUESTIONS,
};

export default savingsCheckMutationsUserless;
