import { gql } from '@apollo/client';
import { UPDATE_ENERGY_PRICES } from '~/hooks/usePersonalEnergyPrices';

// MUTATIONS

export const UPDATE_CURRENT_OCCUPANT = gql`
  mutation updateCurrentOccupant($houseId: ID!, $isCurrentOccupant: Boolean!) {
    updateCurrentOccupant(houseId: $houseId, isCurrentOccupant: $isCurrentOccupant) {
      id
      currentOccupant
    }
  }
`;

export const UPDATE_APARTMENT_TYPE = gql`
  mutation updateApartmentType($houseId: ID!, $apartmentProperties: [String]) {
    updateApartmentType(houseId: $houseId, apartmentProperties: $apartmentProperties) {
      id
      type
    }
  }
`;

export const UPDATE_HOUSE_WALL_CAVITY = gql`
  mutation houseHasWallCavity($houseId: ID!, $hasWallCavity: Boolean) {
    houseHasWallCavity(houseId: $houseId, hasWallCavity: $hasWallCavity) {
      id
      walls {
        cavity
      }
    }
  }
`;

export const UPDATE_HOUSE_WALL_INSULATION = gql`
  mutation houseHasWallInsulation($houseId: ID!, $hasWallInsulation: Boolean) {
    houseHasWallInsulation(houseId: $houseId, hasWallInsulation: $hasWallInsulation) {
      id
      walls {
        rc
      }
    }
  }
`;

export const UPDATE_HOUSE_WALL_INSULATION_EXTRA = gql`
  mutation houseHasExtraWallInsulation($houseId: ID!, $hasExtraWallInsulation: Boolean) {
    houseHasExtraWallInsulation(
      houseId: $houseId
      hasExtraWallInsulation: $hasExtraWallInsulation
    ) {
      id
      walls {
        rc
      }
    }
  }
`;

export const UPDATE_HOUSE_HAS_CRAWLSPACE = gql`
  mutation houseHasCrawlspace($houseId: ID!, $hasCrawlspace: Boolean) {
    houseHasCrawlspace(houseId: $houseId, hasCrawlspace: $hasCrawlspace) {
      id
      crawlspace {
        compartments
      }
    }
  }
`;

export const UPDATE_HOUSE_FLOOR_INSULATION = gql`
  mutation houseHasFloorInsulation($houseId: ID!, $hasFloorInsulation: Boolean) {
    houseHasFloorInsulation(houseId: $houseId, hasFloorInsulation: $hasFloorInsulation) {
      id
      floor {
        rc
      }
    }
  }
`;

export const UPDATE_HOUSE_FLOOR_INSULATION_EXTRA = gql`
  mutation houseHasExtraFloorInsulation($houseId: ID!, $hasExtraFloorInsulation: Boolean) {
    houseHasExtraFloorInsulation(
      houseId: $houseId
      hasExtraFloorInsulation: $hasExtraFloorInsulation
    ) {
      id
      floor {
        rc
      }
    }
  }
`;

export const UPDATE_HOUSE_ROOF_INSULATION = gql`
  mutation houseHasRoofInsulation($houseId: ID!, $hasRoofInsulation: Boolean) {
    houseHasRoofInsulation(houseId: $houseId, hasRoofInsulation: $hasRoofInsulation) {
      id
      roof {
        rc
      }
    }
  }
`;

export const UPDATE_HOUSE_ROOF_INSULATION_EXTRA = gql`
  mutation houseHasExtraRoofInsulation($houseId: ID!, $hasExtraRoofInsulation: Boolean) {
    houseHasExtraRoofInsulation(
      houseId: $houseId
      hasExtraRoofInsulation: $hasExtraRoofInsulation
    ) {
      id
      roof {
        rc
      }
    }
  }
`;

export const UPDATE_HOUSE_LIVING_GLAZING = gql`
  mutation houseHasLivingGlazing($houseId: ID!, $livingGlassType: QuestioningGlassType) {
    houseHasLivingGlazing(houseId: $houseId, glassType: $livingGlassType) {
      id
      windowsZTAU {
        livingU
      }
    }
  }
`;

export const UPDATE_HOUSE_SLEEPING_GLAZING = gql`
  mutation houseHasSleepingGlazing($houseId: ID!, $sleepingGlassType: QuestioningGlassType) {
    houseHasSleepingGlazing(houseId: $houseId, glassType: $sleepingGlassType) {
      id
      windowsZTAU {
        sleepingU
      }
    }
  }
`;

export const UPDATE_HOUSE_PV_SYSTEM = gql`
  mutation houseHasPVSystem($houseId: ID!, $panelAmount: Int) {
    houseHasPVSystem(houseId: $houseId, panelAmount: $panelAmount) {
      id
      pvSystemPower
    }
    updatePvPanelAmount(houseId: $houseId, panelAmount: $panelAmount) {
      id
      solar {
        currentSystem {
          panels
        }
      }
    }
  }
`;

export const UPDATE_HOUSE_BOILER_AGE = gql`
  mutation houseHasBoilerAge($houseId: ID!, $boilerAge: Int) {
    houseHasBoilerAge(houseId: $houseId, age: $boilerAge) {
      id
      heating {
        boilerAge
      }
    }
  }
`;

export const UPDATE_HOUSE_WEATHER_STRIPPING = gql`
  mutation houseHasWeatherStripping($houseId: ID!, $hasWeatherStripping: Boolean!) {
    houseHasWeatherStripping(houseId: $houseId, hasWeatherStripping: $hasWeatherStripping) {
      id
      heating {
        weatherStripping
      }
    }
  }
`;

export const UPDATE_HOUSE_SLURPERS = gql`
  mutation addCurrentSlurpers($houseId: ID!, $slurpers: [String]!) {
    addCurrentSlurpers(houseId: $houseId, slurpers: $slurpers) {
      id
      currentSlurpers
      slurperConsumption
      remainingGasPercentage
      consumption {
        gas
        electricity
      }
    }
  }
`;

export const UPDATE_MOTIVATION = gql`
  mutation updateMotivation($userId: String!, $userType: String, $motivation: String!) {
    updateMotivation(userId: $userId, userType: $userType, motivation: $motivation) {
      id
      ... on Lead {
        motivation
      }
      ... on Customer {
        motivation
      }
    }
  }
`;

export const UPDATE_CONSUMPTION = gql`
  mutation updateConsumption($houseId: ID!, $consumption: ConsumptionInput!) {
    updateConsumption(houseId: $houseId, consumption: $consumption) {
      id
      consumption {
        gas
        electricity
      }
      slurperConsumption
    }
  }
`;

export const SET_EXPERIMENT_METADATA = gql`
  mutation setABTestEntryMetadata($userId: ID!, $domain: String!, $key: String!, $value: String!) {
    setABTestEntryMetadata(userId: $userId, domain: $domain, key: $key, value: $value) {
      id
    }
  }
`;

export const UPDATE_RESIDENT_AMOUNT = gql`
  mutation houseResidentAmount($houseId: ID!, $residentAmount: Int) {
    houseResidentAmount(houseId: $houseId, amount: $residentAmount) {
      id
      residents
      energy {
        gasConsumption
        electricityConsumption
        electricityProduction
      }
    }
  }
`;

export const UPDATE_HOUSE_HAS_BATH = gql`
  mutation houseHasBath($houseId: ID!, $hasBath: Boolean!) {
    houseHasBath(houseId: $houseId, hasBath: $hasBath) {
      id
      appliances {
        bathtub
      }
    }
  }
`;

export const UPDATE_HOUSE_HAS_DISHWASHER = gql`
  mutation houseHasDishWasher($houseId: ID!, $hasDishWasher: Boolean!) {
    houseHasDishWasher(houseId: $houseId, hasDishWasher: $hasDishWasher) {
      id
      appliances {
        dishwasher
      }
    }
  }
`;

export const UPDATE_HOUSE_IS_VVE = gql`
  mutation houseIsVVE($houseId: ID!, $isVve: Boolean) {
    houseIsVVE(houseId: $houseId, isVve: $isVve) {
      id
      vve
    }
  }
`;

export const FINISH_INITIAL_QUESTIONS = gql`
  mutation finishInitialQuestions($houseId: ID!, $email: String, $journeyData: JourneyEventInput) {
    finishInitialQuestions(houseId: $houseId, email: $email, journeyData: $journeyData) {
      id
      initialQuestionsAnswered
    }
  }
`;

const savingsCheckMutations = {
  UPDATE_CURRENT_OCCUPANT,
  UPDATE_APARTMENT_TYPE,
  UPDATE_HOUSE_WALL_CAVITY,
  UPDATE_HOUSE_WALL_INSULATION,
  UPDATE_HOUSE_WALL_INSULATION_EXTRA,
  UPDATE_HOUSE_HAS_CRAWLSPACE,
  UPDATE_HOUSE_FLOOR_INSULATION,
  UPDATE_HOUSE_FLOOR_INSULATION_EXTRA,
  UPDATE_HOUSE_ROOF_INSULATION,
  UPDATE_HOUSE_ROOF_INSULATION_EXTRA,
  UPDATE_HOUSE_LIVING_GLAZING,
  UPDATE_HOUSE_SLEEPING_GLAZING,
  UPDATE_HOUSE_PV_SYSTEM,
  UPDATE_HOUSE_BOILER_AGE,
  UPDATE_HOUSE_WEATHER_STRIPPING,
  UPDATE_HOUSE_SLURPERS,
  UPDATE_MOTIVATION,
  UPDATE_CONSUMPTION,
  SET_EXPERIMENT_METADATA,
  UPDATE_RESIDENT_AMOUNT,
  UPDATE_HOUSE_HAS_BATH,
  UPDATE_HOUSE_HAS_DISHWASHER,
  UPDATE_HOUSE_IS_VVE,
  FINISH_INITIAL_QUESTIONS,
  UPDATE_ENERGY_PRICES,
} as const;

export default savingsCheckMutations;
