import { useMutation } from '@apollo/client';
import { Center } from '@energiebespaarders/symbols/helpers';
import { captureException as SentryCaptureException } from '@sentry/nextjs';
import { captureException as LogRocketCaptureException } from 'logrocket';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import config from '../config';
import { useClearSession } from '../hooks/useClearSession';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import { LOGOUT_CUSTOMER } from '../queries/customer';
import { logoutCustomer } from '../types/generated/logoutCustomer';

// eslint-disable-next-line @typescript-eslint/ban-types
const ContactInfoFallback = dynamic<{}>(() =>
  import('../components/ContactModal').then(mod => mod.ContactInfoFallback),
);

interface ILoadErrorProps {
  error?: Error;
  isPage?: boolean;
  /** A custom message that replaces the entire contents of this component */
  msg?: string;
  /** A custom message that replaces the generic "Er is iets misgegaan bij het laden.", intended to be more specific,
   * while retaining the content about refreshing and contact information
   */
  introMsg?: string;
  /** Extra info for Sentry/Logrocket */
  debugMsg?: string;
}

export const LoadError = ({ error, isPage, msg, introMsg, debugMsg }: ILoadErrorProps) => {
  if (error) {
    if (config.isProduction) {
      try {
        LogRocketCaptureException(error, {
          extra: { loadError: JSON.stringify({ msg, introMsg, debugMsg }, null, 2) },
        });
      } catch (e) {
        console.error('LogRocket capturing error', e, error);
      }
      try {
        SentryCaptureException(error, {
          contexts: { loadError: { isPage, msg, introMsg, debugMsg } },
        });
      } catch (e) {
        console.error('Sentry capturing error', e, error);
      }
    } else {
      console.error(error, debugMsg);
    }
  }
  const isAuthenticated = useIsAuthenticated();
  const { clearSession } = useClearSession();
  const router = useRouter();
  const [logout] = useMutation<logoutCustomer>(LOGOUT_CUSTOMER, {
    onCompleted: async data => data.logoutCustomer && clearSession('/login'),
  });
  return (
    <Center block>
      <br />
      {msg ? (
        <div>{msg}</div>
      ) : (
        <div>
          <p>
            {introMsg || 'Er is iets misgegaan bij het laden.'}{' '}
            <span onClick={router.reload} className="link-green medium">
              Ververs de pagina
            </span>{' '}
            en neem contact met ons op als het probleem zich blijft voordoen.
          </p>

          {isAuthenticated.asCustomer && (
            <p>
              Je kunt ook proberen{' '}
              <span onClick={() => logout()} className="link-green medium">
                opnieuw in te loggen
              </span>{' '}
              om fouten te verhelpen.
            </p>
          )}

          <ContactInfoFallback />
        </div>
      )}
    </Center>
  );
};

export default LoadError;
